<template>
  <div id="gist"></div>
</template>
<script>
/* eslint-disable no-useless-escape */
import postscribe from "postscribe";
export default {
  name: "Gist-Example",
  mounted: function () {
    postscribe(
      "#gist",
      `<script type='text/javascript' src='//www.opentable.ie/widget/reservation/loader?rid=286047&type=button&theme=standard&color=1&dark=false&iframe=true&domain=ie&lang=en-IE&newtab=false&ot_source=Restaurant%20website'><\/script>`
    );
  },
};
</script>
